// Global
export const resizeDebounceTime = 10 // in ms
export const hideStaticHtml = false // sets page to be invisible until FE app kicks in (removes static content flash)

// Head
export const siteName = 'Barbara Lopez'
export const siteDescription =
  'Barbara Lopez is an interactive designer based in New York. Pushing the boundaries of visual design to create cutting-edge digital experiences for brands.'
export const siteSlogan =
  'Interactive Designer based in New York, creating cutting-edge digital experiences for brands.'
export const siteKeywords = ['Barbara Lopez', 'Designer', 'Design', 'Interactive Designer', 'Visual Designer']
