import { FC, memo, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { nanoid } from '@reduxjs/toolkit'
import classNames from 'classnames'
import gsap from 'gsap'
import { shallow } from 'zustand/shallow'

import css from './Layout.module.scss'

import routes from '@/data/routes'
import { PageHandle, PageProps } from '@/data/types'

import localStore from '@/store'

import { slugToString } from '@/utils/basic-functions'
import { isLocalhost } from '@/utils/runtime-env'
import sass from '@/utils/sass'
import scrollPage from '@/utils/scroll-page'

import { useRefs } from '@/hooks/use-refs'

import AppAdmin from '@/components/AppAdmin/AppAdmin'
import BackButton from '@/components/BackButton/BackButton'
import DebugGrid from '@/components/DebugGrid/DebugGrid'
import Head from '@/components/Head/Head'
import Loader from '@/components/Loader/Loader'
import Nav, { NavHandle } from '@/components/Nav/Nav'

export type LayoutRefs = {
  pathname: string
  navHandle: NavHandle | null
  pageHandle: PageHandle | null
  isFirstPage: boolean
  scrollRestorationTimeout: NodeJS.Timeout
}

const Layout: FC<AppProps<PageProps>> = ({ Component, pageProps }) => {
  const router = useRouter()
  const [fontsReady, setFontsReady] = useState(false)

  const [setCurrentProject, setCurrentPath] = localStore(
    ({ app }) => [app.setCurrentProject, app.setCurrentPath],
    shallow
  )

  const [currentPage, setCurrentPage] = useState<ReactNode>(<Component key="first-page" {...pageProps} />)
  const [loaderDone, setLoaderDone] = useState(isLocalhost())

  const refs = useRefs<LayoutRefs>({
    pathname: useRef('/'),
    isFirstPage: useRef(true)
  })

  const handleLoaderComplete = useCallback(() => {
    setLoaderDone(true)
  }, [])

  //
  // Load fonts
  //
  useEffect(() => {
    if (document.fonts) {
      void document.fonts.ready.then(function () {
        setFontsReady(true)
      })
    }
  }, [])

  //
  // Navigation utils - save previous path
  //
  useEffect(() => {
    const handleRouteChange = (route: string) => {
      setCurrentPath(route)
    }

    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [router, setCurrentPath])

  //
  // Page background
  //
  useEffect(() => {
    const onBeforeHistoryChange = (route: string) => {
      const html = document.documentElement

      switch (route) {
        case routes.Home.path:
        case routes.Photo.path:
        case routes.Gallery.path:
        case routes.Work.path:
        case routes.NotFound.path:
          gsap.set(html, {
            backgroundColor: sass.white
          })

          break
        default: {
          if (route.includes(routes.Work.path)) {
            gsap.set(html, {
              backgroundColor: sass.black
            })
          }
          break
        }
      }
    }

    router.events.on('beforeHistoryChange', onBeforeHistoryChange)
    return () => {
      router.events.off('beforeHistoryChange', onBeforeHistoryChange)
    }
  }, [router])

  //
  // Handle project slug
  //
  useEffect(() => {
    if (router.query?.project) {
      setCurrentProject(slugToString(router.query.project as string))
    } else {
      setCurrentProject('')
    }
  }, [router.query, setCurrentProject])

  //
  // Update pathname ref
  //
  useEffect(() => {
    refs.pathname.current = router.asPath
      .split('#')[0]
      .split('?')[0]
      .replace(/^\/..-..\/?/u, '')
  }, [refs, router.asPath])

  //
  // Page transitions
  //
  useEffect(() => {
    if (!loaderDone) return

    const transitionTimeline = gsap.timeline()

    // if the current page has an animateOut(), do it
    if (refs.pageHandle.current?.animateOut) {
      transitionTimeline.add(refs.pageHandle.current.animateOut())
    }

    // after the out animation, set the new page
    transitionTimeline.add(() => {
      // reset scroll
      scrollPage({ x: 0, y: 0 })
      // set new page
      setCurrentPage(
        <Component
          key={refs.isFirstPage.current ? 'first-page' : nanoid()}
          {...pageProps}
          onReady={(pageHandle?: RefObject<PageHandle>) => {
            refs.pageHandle.current = pageHandle?.current || null
            // animate in
            refs.pageHandle.current?.animateIn?.()
            refs.navHandle.current?.animateIn?.()
          }}
        />
      )
      refs.isFirstPage.current = false
    })

    return () => {
      transitionTimeline.kill()
    }
  }, [refs, Component, pageProps, loaderDone])

  return (
    <div className={classNames('Layout', css.root)}>
      <Head {...pageProps.head} />
      <Nav handleRef={refs.navHandle} />

      {loaderDone ? (
        <>
          <BackButton />
        </>
      ) : null}

      {!loaderDone && fontsReady ? <Loader handleComplete={handleLoaderComplete} /> : null}
      {isLocalhost() ? <DebugGrid /> : null}
      {isLocalhost() ? <AppAdmin /> : null}
      <div className={css.content}>{currentPage}</div>
    </div>
  )
}

export default memo(Layout)
