/* eslint-disable eslint-comments/disable-enable-pair */
import { FC, ForwardedRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'

import css from './Nav.module.scss'

import routes, { projectRoutes } from '@/data/routes'

import { toRem } from '@/utils/basic-functions'
import { MOTION } from '@/utils/motion-values'
import { isLocalhost } from '@/utils/runtime-env'

import useClickAway from '@/hooks/use-click-away'
import useLayout from '@/hooks/use-layout'
import { useRefs } from '@/hooks/use-refs'

import NavLink from '@/components/NavLink/NavLink'
import PillButton from '@/components/PillButton/PillButton'

import CloseButtonSvg from '@/svgs/close.svg'

export type NavProps = {
  className?: string
  handleRef?: ForwardedRef<NavHandle>
}

export type NavHandle = {
  animateIn: () => gsap.core.Timeline
}

export type ViewRefs = {
  root: HTMLDivElement
}

// NAV ROUTES
const navRoutes = [
  { href: routes.Home.path, label: 'HOME', showInProd: true },
  { href: routes.Work.path, label: 'WORK', showInProd: true },
  { href: routes.Photo.path, label: 'PHOTO', showInProd: true },
  { href: routes.Gallery.path, label: 'GALLERY', showInProd: false },
  { href: projectRoutes.Vibia.path, label: 'PROJECT / VIBIA', showInProd: false },
  { href: projectRoutes.PixelArena.path, label: 'PROJECT / PIXEL ARENA', showInProd: false },
  { href: projectRoutes.SergiComellas.path, label: 'PROJECT / SERGI COMELLAS', showInProd: false },
  { href: projectRoutes.SonosRoam.path, label: 'PROJECT / SONOS ROAM', showInProd: false },
  { href: projectRoutes.SonosAce.path, label: 'PROJECT / SONOS ACE', showInProd: false },
  { href: projectRoutes.Archive.path, label: 'PROJECT / ARCHIVE', showInProd: false },
  { href: routes.NotFound.path, label: '404', showInProd: false }
]

const Nav: FC<NavProps> = ({ className, handleRef }) => {
  const theme = 'dark' as const
  const layout = useLayout()
  const viewRefs = useRefs<ViewRefs>()
  const [onReady, setReady] = useState(false)

  useClickAway(viewRefs.root, () => setClosed(true))

  const [closed, setClosed] = useState(true)
  const timelineRef = useRef<gsap.core.Timeline>()

  const toggleBgRef = useRef<HTMLDivElement>(null)
  const toggleTextRef = useRef<HTMLParagraphElement>(null)

  useImperativeHandle(handleRef, () => ({
    animateIn: () => gsap.timeline().to(viewRefs.root.current, { duration: 0.8, autoAlpha: 1, ease: 'ease-opacity' }, 1)
  }))

  const handleOnClicked = useCallback(() => {
    setClosed(true)
  }, [])

  useEffect(() => {
    gsap.set(viewRefs.root.current, { opacity: 0 })

    const readyTimeout = setTimeout(() => {
      setReady(true)
    }, MOTION.general.delayForLayout)

    return () => {
      if (readyTimeout) clearTimeout(readyTimeout)
    }
  }, [viewRefs])

  useEffect(() => {
    if (!onReady) return

    const isMobile = layout.device
    const allLinks = Array.from(viewRefs.root.current?.querySelectorAll('a') || [])

    timelineRef.current = gsap
      .timeline({ paused: true })
      .set(viewRefs.root.current, { opacity: 1 })
      .fromTo(toggleTextRef.current, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.334, ease: 'ease-opacity' }, '<')
      .fromTo(
        toggleBgRef.current,
        { width: '100%' },
        { width: '68%', duration: 0.667, ease: 'ease-transform' },
        '<+0.2'
      )
      .fromTo(`.${css.svgCross}`, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.334, ease: 'ease-opacity' }, '<')
      .fromTo(`.${css.svgCross}`, { rotate: -90 }, { rotate: 0, duration: 0.667, ease: 'ease-transform' }, '<')

    if (isMobile) {
      // Reset x positions on layout change
      timelineRef.current.set(allLinks, { y: 0, overwrite: true }, '<')

      timelineRef.current
        .fromTo(
          allLinks,
          {
            autoAlpha: 0
          },
          {
            autoAlpha: 1,
            duration: 0.334,
            stagger: 0.1,
            ease: 'ease-opacity'
          },
          '<+0.2'
        )
        .fromTo(
          allLinks,
          {
            x: toRem(25)
          },
          {
            duration: 0.667,
            x: toRem(17),
            stagger: 0.1,
            ease: 'ease-transform'
          },
          '<'
        )
    } else {
      // Reset x positions on layout change
      timelineRef.current.set(allLinks, { x: 0, overwrite: true }, '<')

      timelineRef.current
        .fromTo(
          allLinks,
          {
            autoAlpha: 0
          },
          {
            autoAlpha: 1,
            duration: 0.334,
            stagger: 0.1,
            ease: 'ease-opacity'
          },
          '<+0.2'
        )
        .fromTo(
          allLinks,
          {
            y: toRem(25)
          },
          {
            duration: 0.667,
            y: 0,
            stagger: 0.1,
            ease: 'ease-transform'
          },
          '<'
        )
    }

    return () => {
      if (timelineRef.current) timelineRef.current.kill()
    }
  }, [layout.device, onReady, viewRefs])

  useEffect(() => {
    setClosed(true)
  }, [layout])

  useEffect(() => {
    if (timelineRef.current) {
      if (closed) {
        timelineRef.current.reverse()
      } else {
        timelineRef.current.play()
      }
    }
  }, [closed])

  return (
    <nav className={classNames('Nav', css.root, className)} ref={viewRefs.root}>
      <button onClick={() => setClosed(!closed)} className={css.toggleButton}>
        <div className={css.background} ref={toggleBgRef}></div>
        <p className={css.menu} ref={toggleTextRef}>
          MENU
        </p>
        <CloseButtonSvg className={css.svgCross} />
      </button>

      {navRoutes.map((route, index) => {
        if (!isLocalhost() && !route.showInProd) return null

        const pillButtonProps = {
          // @NOTE: This is active logic if we ever need
          // className: classNames(css.animated, {
          //   [css.active]: route.href === router.asPath
          // }),
          className: css.animated,
          theme
        }

        return (
          <NavLink key={index} href={route.href}>
            <PillButton {...pillButtonProps} onClick={handleOnClicked}>
              {route.label}
            </PillButton>
          </NavLink>
        )
      })}
    </nav>
  )
}

export default memo(Nav)
