import gsap from 'gsap'

interface ScrollProps {
  x: number
  y: number
  duration: number
  ease: string
}

const defaultProps: ScrollProps = {
  x: 0,
  y: 0,
  duration: 0, // in seconds
  ease: 'none'
}

/**
 * Scroll page to a specific position
 *
 * @param {object} [props={}] - Scroll options. Refer to 'defaultProps' object
 */
export default function scrollPage(props: Partial<ScrollProps> = {}) {
  const combinedProps = Object.assign({}, defaultProps, props)
  const { x, y, duration, ease } = combinedProps

  gsap.to(window, { duration, scrollTo: { x, y, autoKill: false }, ease })
}
