import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { createWithEqualityFn } from 'zustand/traditional'

import { AppSlice, AppSliceState } from './slice-app'

export type Mutators = [['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]]

export type AppState = AppSliceState

const localStore = createWithEqualityFn<AppState>()(
  devtools(
    subscribeWithSelector(
      immer((...props) => ({
        ...AppSlice(...props)
      }))
    )
  )
)

export const localState = () => localStore.getState()

export default localStore
