import { ForwardedRef, forwardRef, memo, ReactNode } from 'react'
import classNames from 'classnames'

import css from './Label.module.scss'

export type LabelHandle = {
  //
}

export interface LabelProps {
  className?: string
  children?: ReactNode
  handleRef?: ForwardedRef<LabelHandle>
  labelType?: 1 | 2 | 3
  uppercase?: boolean
  italic?: boolean
}

export interface ViewProps extends LabelProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLDivElement, ViewProps>(
  ({ className, labelType = 1, uppercase = true, italic = false, children }, ref) => {
    return (
      <span
        className={classNames('Label', css.root, className, css[`label-${labelType}`], {
          [css.uppercase]: uppercase,
          [css.italic]: italic
        })}
        ref={ref}
      >
        {children}
      </span>
    )
  }
)

View.displayName = 'Label-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Label = forwardRef<HTMLDivElement, LabelProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

Label.displayName = 'Label'

export default memo(Label)
