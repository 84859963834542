import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

import { browser, device, os } from '@/utils/detect'

type ResizeListener = (e?: Event | UIEvent) => void

let timeout: NodeJS.Timeout
class Service {
  debounceTime = 100 // in ms
  listeners: ResizeListener[] = []

  bruteForceInterval?: NodeJS.Timer
  bruteForceHeight = 0
  bruteForceWidth = 0

  onResize = (e: Event | UIEvent) => {
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      if (device.mobile) {
        timeout = setTimeout(() => {
          if (window.innerHeight > screen.height) this.onResize(e)
          this.listeners.forEach((listener) => listener(e))
          setTimeout(ScrollTrigger.refresh, 100)
        }, 500) // some mobile browsers only update window dimensions when the rotate animation finishes
      } else {
        this.listeners.forEach((listener) => listener(e))
        setTimeout(ScrollTrigger.refresh, 100)
      }
    }, this.debounceTime)
  }

  listen = (listener: ResizeListener) => {
    if (!this.listeners.length) {
      if (device.mobile) {
        // window.addEventListener('resize', this.onResize)
        window.addEventListener('orientationchange', this.onResize)
      } else {
        window.addEventListener('resize', this.onResize)
      }
      if (browser.inApp && os.ios) {
        this.enableBruteForce()
      }
    }
    if (!this.listeners.includes(listener)) this.listeners.push(listener)
  }

  dismiss = (listener: ResizeListener) => {
    this.listeners = this.listeners.filter((l) => l !== listener)
    if (!this.listeners.length) {
      window.removeEventListener('resize', this.onResize)
      if (device.mobile) window.removeEventListener('orientationchange', this.onResize)
      this.disableBruteForce()
    }
  }

  enableBruteForce = () => {
    this.disableBruteForce()
    this.bruteForceInterval = setInterval(() => {
      if (window.innerWidth !== this.bruteForceWidth || window.innerHeight !== this.bruteForceHeight) {
        this.bruteForceWidth = window.innerWidth
        this.bruteForceHeight = window.innerHeight
        this.listeners.forEach((listener) => listener())
      }
    }, 500)
  }

  disableBruteForce = () => {
    if (this.bruteForceInterval) clearInterval(this.bruteForceInterval)
  }
}

const ResizeService = new Service()

export default ResizeService
