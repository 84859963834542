import { FC, memo } from 'react'
import classNames from 'classnames'
import { shallow } from 'zustand/shallow'

import css from './DebugGrid.module.scss'

import localStore, { AppState } from '@/store'

import useLayout from '@/hooks/use-layout'

export interface DebugGridProps {
  className?: string
}

export interface ViewProps extends DebugGridProps {
  debugGridVisibility: AppState['app']['debugGridVisibility']
  setDebugGridVisibility: AppState['app']['setDebugGridVisibility']
  debugGridTheme: AppState['app']['debugGridTheme']
  setDebugGridTheme: AppState['app']['setDebugGridTheme']
}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({ className, debugGridVisibility, debugGridTheme }) => {
  const layout = useLayout()
  const columnsCount = layout.mobile ? 6 : layout.tablet ? 8 : 12

  if (!debugGridVisibility) {
    return null
  }

  return (
    <div
      className={classNames('DebugGrid', css.root, className, {
        [css.themeDark]: debugGridTheme === 'dark',
        [css.themeLight]: debugGridTheme === 'light'
      })}
    >
      <div className={css.container}>
        {Array(columnsCount)
          .fill(0)
          .map((_, i) => (
            <div className={css.column} key={i} />
          ))}
      </div>
      <div className={css.diagonalContainer}>
        {Array(4)
          .fill(0)
          .map((_, i) => (
            <div className={css.column} key={i} />
          ))}
      </div>
    </div>
  )
}

View.displayName = 'DebugGrid-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const DebugGrid: FC<DebugGridProps> = (props) => {
  const [debugGridVisibility, setDebugGridVisibility, debugGridTheme, setDebugGridTheme] = localStore(
    ({ app }) => [app.debugGridVisibility, app.setDebugGridVisibility, app.debugGridTheme, app.setDebugGridTheme],
    shallow
  )

  return (
    <View
      {...props}
      debugGridVisibility={debugGridVisibility}
      setDebugGridVisibility={setDebugGridVisibility}
      debugGridTheme={debugGridTheme}
      setDebugGridTheme={setDebugGridTheme}
    />
  )
}

export default memo(DebugGrid)
