import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import CustomEase from 'gsap/dist/CustomEase'
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import SplitText from 'gsap/dist/SplitText'

declare global {
  interface Window {
    lenis: Lenis
  }
}

function initGsap() {
  if (typeof window === 'undefined') return

  gsap.registerPlugin(CustomEase, ScrollToPlugin, ScrollTrigger, SplitText)

  gsap.defaults({ ease: 'none', duration: 1 })

  CustomEase.create('ease-opacity', '0.23, 1, 0.32, 1') // was linear before
  CustomEase.create('ease-transform', '0.075, 0.82, 0.165, 1') // same
  CustomEase.create('ease-scale', '0.075, 0.82, 0.165, 1') // same
  CustomEase.create('ease-wipe', '0.075, 0.82, 0.165, 1') // same
  CustomEase.create('ease-split-text', '0.075, 0.82, 0.165, 1') // same
  CustomEase.create('ease-x-y-percent', '0.4, 0, 0.1, 1') // old ease-02
  CustomEase.create('ease-slot-machine', '0.65, 0, 0.2, 1') // old ease-loader
  CustomEase.create('ease-jam3-loader', '0.33, 0.0, 0.0, 1.0') // new ease-loader

  // @NOTE: Not being used yet
  CustomEase.create('ease-image', '0.25, 0.46, 0.45, 0.94')
  CustomEase.create('ease-transform-fast', '0.19, 1, 0.22, 1')

  // @NOTE: CSS
  CustomEase.create('css-ease-out', '0, 0, 0.58, 1')

  if (!process.env.STORYBOOK) {
    // Register only the effects you need.
    // Storybook will register all effects in .storybook/intro/Effects.stories.tsx

    gsap.registerEffect(require('@/effects/mask/maskWipeIn/maskWipeIn').default)
    gsap.registerEffect(require('@/effects/mask/maskWipeOut/maskWipeOut').default)

    gsap.registerEffect(require('@/effects/text/textRiseByLinesIn/textRiseByLinesIn').default)

    gsap.registerEffect(require('@/effects/text/textRiseByCharsIn/textRiseByCharsIn').default)
    gsap.registerEffect(require('@/effects/text/textRiseByCharsOut/textRiseByCharsOut').default)
  }

  // ScrollSmooth
  const lenis = new Lenis()
  window.lenis = lenis

  lenis.on('scroll', ScrollTrigger.update)

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
  })

  gsap.ticker.lagSmoothing(0)
}

export default initGsap
