import { StaticImageData } from 'next/image'
import gsap from 'gsap'

import { type GalleryCopyType, type GalleryImageType } from '@/data/fixed-pages/copy-gallery'
import { StaticVideoData } from '@/data/types'

export function cleanUrl(path = '', cleanParams = false): string {
  if (!path) {
    return ''
  }
  if (cleanParams) {
    path = path?.split('?')[0]
  }
  if (path === '/') {
    return '/'
  }

  return path.replace(/\/$/, '').replace(/^\//, '') || ''
}

export function padLeadingZeros(num: number): string {
  return num.toString().padStart(2, '0')
}

export function getImageUrl(filePath: string): StaticImageData {
  return require(`../assets/images/${filePath}`).default
}

export const getThumbsUrl = (filePath: string) => {
  return `/common/assets/images/download-thumbnails/${filePath}`
}

//pdfs only
export const getDocumentsUrl = (filePath: string) => {
  return `/common/assets/documents/${filePath}`
}

export const getLocalVideoUrl = (filePath: string): StaticVideoData => {
  return { src: `/common/assets/videos/${filePath}` }
}

export const getDownloadsUrl = (filePath: string) => {
  return `/common/assets/images/downloads/${filePath}`
}

export const getAssetTitle = (path: string | undefined) => {
  if (!path) return ''
  const array = path.split('/')
  return array[array?.length - 1]
}

export function noop() {
  // no op function
}

export function getScrollTop() {
  return window.scrollY || document.body.scrollTop
}

export function getScrollLeft() {
  return window.scrollX || document.body.scrollLeft
}

export function rem(value: number): number {
  const htmlStyle = window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size')
  return parseFloat(htmlStyle) * value
}

export function randomString(length = 6): string {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export function slugToString(slug: string) {
  return slug.replace(/-/g, ' ').replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

type OutputCleanup = [
  {
    images: GalleryImageType[]
    descriptions: (string | null | undefined)[]
  },
  {
    left: GalleryImageType[]
    right: GalleryImageType[]
    descriptions: (string | null | undefined)[]
    isRightException: boolean[]
  }
]

export function cleanupImageDataForGallery(images: GalleryCopyType['images']): OutputCleanup {
  const originalImages = images
  const mobileImagesCleanup = Object.values(originalImages).filter((el) => Boolean(el))
  const mobileImagesArray = mobileImagesCleanup.map((el) => el)
  const mobileDescriptionsArray = mobileImagesCleanup.map((el) => el?.label)

  const desktopImagesCleanup = Object.values(originalImages)
  const desktopImagesDoubleArray = []
  for (let i = 0; i < desktopImagesCleanup.length; i += 2) {
    desktopImagesDoubleArray.push([desktopImagesCleanup[i], desktopImagesCleanup[i + 1]])
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const leftImagesArray = desktopImagesDoubleArray.map(([left, _]) => left)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rightImagesArray = desktopImagesDoubleArray.map(([_, right]) => right)
  const descriptionsArray = desktopImagesDoubleArray.map(([left, right]) => {
    if (left?.label === right?.label) {
      return left?.label || right?.label
    } else {
      if (!left?.label) return right?.label
      if (!right?.label) return left?.label

      return `${left.label}, ${right.label}`
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isRightExceptionArray = desktopImagesDoubleArray.map(([left, _]) => !left.imageData)

  const desktopData = {
    left: leftImagesArray,
    right: rightImagesArray,
    descriptions: descriptionsArray,
    isRightException: isRightExceptionArray
  }

  const mobileData = {
    images: mobileImagesArray.filter((el) => Boolean(el.imageData)),
    descriptions: mobileDescriptionsArray.filter((el) => Boolean(el))
  }

  return [mobileData, desktopData]
}

export function killTimeline(
  timeline: gsap.core.Timeline | null = null,
  removeScrollTrigger = false,
  clearPropsTargets: Element[] = []
) {
  if (!timeline) return

  if (removeScrollTrigger) {
    timeline.scrollTrigger?.kill(true)
  }

  timeline.kill()

  for (let i = 0; i < clearPropsTargets.length; i++) {
    if (clearPropsTargets[i]) {
      gsap.set(clearPropsTargets[i], { clearProps: 'all' })
    }
  }
}

export function updateCustomProperties({
  backButtonVisibility,
  backButtonColor,
  backButtonOpacity,
  navButtonBackground,
  navButtonColor,
  navButtonBorder,
  backButtonTransitionDuration
}: {
  backButtonVisibility?: string
  backButtonColor?: string
  backButtonOpacity?: string
  navButtonBackground?: string
  navButtonColor?: string
  navButtonBorder?: string
  backButtonTransitionDuration?: string
}) {
  if (typeof window === 'undefined') return

  const root = document.documentElement
  const style = root.style

  // Back Button

  if (backButtonVisibility) {
    style.setProperty('--back-button-visibility', backButtonVisibility)
  }

  if (backButtonColor) {
    style.setProperty('--back-button-color', backButtonColor)
  }

  if (backButtonOpacity) {
    style.setProperty('--back-button-opacity', backButtonOpacity)
  }

  // Nav Button

  if (navButtonBackground) {
    style.setProperty('--nav-button-background', navButtonBackground)
  }

  if (navButtonColor) {
    style.setProperty('--nav-button-color', navButtonColor)
  }

  if (navButtonBorder) {
    style.setProperty('--nav-button-border', navButtonBorder)
  }

  if (backButtonTransitionDuration) {
    style.setProperty('--back-button-transition-duration', backButtonTransitionDuration)
  }
}

export function reverseArray(array: unknown[]) {
  return array.slice().reverse()
}

export function toRem(value: number): string {
  return `${value / 10}rem`
}
