import { forwardRef, memo, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import css from './NavLink.module.scss'

export interface NavLinkProps extends LinkProps {
  className?: string
  href: string
  children: ReactNode
}

export interface ViewProps extends NavLinkProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLAnchorElement, ViewProps>(({ className, href, children, ...props }, ref) => {
  const router = useRouter()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // Check if the destination URL is the same as the current URL
    if (router.asPath === href) {
      e.preventDefault()
    }
  }

  return (
    <Link
      className={classNames('NavLink', css.root, className)}
      {...props}
      href={href}
      ref={ref}
      onClick={handleClick}
      scroll={false}
    >
      {children}
    </Link>
  )
})

View.displayName = 'NavLink-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

NavLink.displayName = 'NavLink'

export default memo(NavLink)
