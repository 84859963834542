import { forwardRef, memo, ReactNode } from 'react'
import classNames from 'classnames'

import css from './PillButton.module.scss'

export type PillButtonProps = {
  className?: string
  children: ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  flexAlignment?: 'start' | 'center' | 'end'
  theme?: 'dark' | 'light' | 'modal' | 'border'
  size?: 'small' | 'medium' | 'close'
  isActive?: boolean
}

export interface ViewProps extends PillButtonProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLDivElement, ViewProps>(
  (
    { className, children, onClick, flexAlignment = 'end', theme = 'dark', size = 'small', isActive = false, ...props },
    ref
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classNames(
          'PillButton',
          css.root,
          className,
          css[`flexAlignment-${flexAlignment}`],
          css[`theme-${theme}`],
          css[`size-${size}`],
          {
            [css.active]: isActive
          }
        )}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
  }
)

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const PillButton = forwardRef<HTMLHeadingElement, PillButtonProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

PillButton.displayName = 'PillButton'

export default memo(PillButton)
