import { FC, memo, useEffect } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import gsap from 'gsap'
import { shallow } from 'zustand/shallow'

import css from './BackButton.module.scss'

import localStore from '@/store'

import { useRefs } from '@/hooks/use-refs'

import Label from '@/components/Label/Label'
import Paragraph from '@/components/Paragraph/Paragraph'

import BackButtonSvg from '@/svgs/back-button.svg'

export type BackButtonProps = {
  className?: string
}

export type ViewRefs = {
  root: HTMLDivElement
  projectContainer: HTMLDivElement
  projectLabel: HTMLDivElement
  currentLabel: HTMLDivElement
}

const BackButton: FC<BackButtonProps> = ({ className }) => {
  const [currentProject] = localStore(({ app }) => [app.currentProject], shallow)
  const viewRefs = useRefs<ViewRefs>()
  const router = useRouter()
  const [currentPath, prevPath] = localStore(({ app }) => [app.currentPath, app.prevPath], shallow)

  const handleGoBack = () => {
    if (currentPath === prevPath) {
      void router.push('/', undefined, { scroll: false })
      return
    }

    if (currentPath !== prevPath) {
      void router.push(prevPath, undefined, { scroll: false })
    }
  }

  useEffect(() => {
    let timeline: gsap.core.Timeline | null = null
    const currentLabel = viewRefs.currentLabel.current

    if (currentProject) {
      timeline = gsap
        .timeline({
          delay: 0.4
        })
        .to(viewRefs.projectContainer.current, { opacity: 1, duration: 0.8, ease: 'ease-opacity' }, 0)
        .fromTo(
          currentLabel,
          { yPercent: 0 },
          { yPercent: -100, duration: 1.2, immediateRender: false, ease: 'ease-x-y-percent' },
          '<+0.1'
        )
        .set(currentLabel, { textContent: currentProject }, '>')
        .fromTo(
          currentLabel,
          { yPercent: 100 },
          { yPercent: 0, duration: 1.2, immediateRender: false, ease: 'ease-x-y-percent' },
          '>+0.1'
        )
    } else {
      timeline = gsap
        .timeline({
          delay: 0.4
        })
        .to(viewRefs.projectContainer.current, { opacity: 0, duration: 0.8, ease: 'ease-opacity' }, 0)
        .set(currentLabel, { textContent: '' }, '>')
    }

    return () => {
      if (timeline) {
        timeline.kill()
      }
    }
  }, [currentProject, viewRefs])

  return (
    <div className={classNames('BackButton', css.root, className)}>
      <button className={css.button} onClick={handleGoBack}>
        <BackButtonSvg className={css.svg} />
        <Label labelType={3}>Back</Label>
      </button>

      <div className={css.currentProjectContainer} ref={viewRefs.projectContainer}>
        <div className={css.line}>|</div>
        <Paragraph paragraphType={2} italic ref={viewRefs.projectLabel}>
          Project
        </Paragraph>
        <Label labelType={3} ref={viewRefs.currentLabel}></Label>
      </div>
    </div>
  )
}

export default memo(BackButton)
