import { ForwardedRef, forwardRef, memo } from 'react'
import classNames from 'classnames'

import css from './Paragraph.module.scss'

export type ParagraphHandle = {
  //
}

export interface ParagraphProps {
  className?: string
  children?: React.ReactNode
  handleRef?: ForwardedRef<ParagraphHandle>
  paragraphType?: 1 | 2
  uppercase?: boolean
  italic?: boolean
  as?: 'p' | 'span' | 'li'
}

export interface ViewProps extends ParagraphProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLElement, ViewProps>(
  ({ className, paragraphType = 1, uppercase = false, italic = false, as = 'p', children }, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Tag = as as any

    return (
      <Tag
        className={classNames('Paragraph', css.root, className, css[`paragraph-${paragraphType}`], {
          [css.uppercase]: uppercase,
          [css.italic]: italic
        })}
        ref={ref}
      >
        {children}
      </Tag>
    )
  }
)

View.displayName = 'Paragraph-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Paragraph = forwardRef<HTMLElement, ParagraphProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

Paragraph.displayName = 'Paragraph'

export default memo(Paragraph)
