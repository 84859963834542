import { ForwardedRef, forwardRef, memo, useEffect, useImperativeHandle, useRef } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'

import css from './SlotMachineCounter.module.scss'

export type SlotMachineCounterHandle = {
  getTimeline: () => gsap.core.Timeline
}

export interface SlotMachineCounterProps {
  className?: string
  handleRef?: ForwardedRef<SlotMachineCounterHandle>
  toNumber?: number
  postfix?: string
}

export interface ViewProps extends SlotMachineCounterProps {}

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLDivElement, ViewProps>(
  ({ className, handleRef, toNumber = 100, postfix = '' }, ref) => {
    const firstDigit = useRef<HTMLDivElement>(null)
    const secondDigit = useRef<HTMLDivElement>(null)
    const thirdDigit = useRef<HTMLDivElement>(null)
    const timelineRef = useRef<gsap.core.Timeline | null>(null)

    useImperativeHandle(handleRef, () => ({
      getTimeline: () => timelineRef.current!
    }))

    useEffect(() => {
      const to = toNumber
      const to3DigitsString = to.toString().padStart(3, '0')

      const [first, second, third] = to3DigitsString.split('')
      const firstIndex = parseInt(first)
      const secondIndex = parseInt(second)
      const thirdIndex = parseInt(third)

      const firstIndexAdvance = firstIndex
      const secondIndexAdvance = firstIndexAdvance * 10 + secondIndex
      const thirdIndexAdvance = secondIndexAdvance * 10 + thirdIndex

      timelineRef.current = gsap
        .timeline({
          defaults: {
            ease: 'ease-jam3-loader',
            duration: 1,
            overwrite: true
          }
        })
        .to(
          firstDigit.current,
          {
            yPercent: -firstIndexAdvance * 100
          },
          0
        )
        .to(
          secondDigit.current,
          {
            yPercent: -secondIndexAdvance * 100
          },
          0
        )
        .to(
          thirdDigit.current,
          {
            yPercent: -thirdIndexAdvance * 100
          },
          0
        )

      return () => {
        timelineRef.current?.kill()
      }
    }, [toNumber])

    return (
      <div className={classNames('SlotMachineCounter', css.root, className)} ref={ref}>
        <div className={css.indicator}></div>

        {/* First Digit acts as 100 so 0 to 1 only */}
        <div className={css.digitWrapper} ref={firstDigit}>
          {Array.from({ length: 10 }).map((_, index) => (
            <div key={index} className={css.digit}>
              {index === 0 ? 0 : index}
            </div>
          ))}
          <div className={css.digit}>0</div>
        </div>

        {/* Second Digit acts as 10 so 0 to 9 */}
        <div className={css.digitWrapper} ref={secondDigit}>
          {Array.from({ length: 10 }).map((_, index) => (
            <div key={index} className={css.digit}>
              {index === 0 ? 0 : index}
            </div>
          ))}
          <div className={css.digit}>0</div>
        </div>

        {/* Third Digit acts as 1 so 0 to 9 */}
        <div className={css.digitWrapper} ref={thirdDigit}>
          {Array.from({ length: 100 }).map((_, index) => (
            <div key={index} className={css.digit}>
              {index === 0 ? 0 : index % 10}
            </div>
          ))}
          <div className={css.digit}>0</div>
        </div>

        {postfix ? <div className={css.postfix}>{postfix}</div> : null}
      </div>
    )
  }
)

View.displayName = 'SlotMachineCounter-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const SlotMachineCounter = forwardRef<HTMLDivElement, SlotMachineCounterProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

SlotMachineCounter.displayName = 'SlotMachineCounter'

export default memo(SlotMachineCounter)
