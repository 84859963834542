import { ForwardedRef, forwardRef, memo, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'

import css from './Loader.module.scss'

import { getImageUrl } from '@/utils/basic-functions'

export type LoaderHandle = {
  //
}

import { StaticImageData } from 'next/image'

import log from '@/utils/logger'

import SlotMachineCounter from '@/components/SlotMachineCounter/SlotMachineCounter'
export interface LoaderProps {
  className?: string
  handleRef?: ForwardedRef<LoaderHandle>
  handleComplete?: () => void
}

export interface ViewProps extends LoaderProps {}

const preloadImagesArray: StaticImageData[] = [
  // Landing Home
  getImageUrl('landing/landing-photo.jpg')
  // Photo
  // getImageUrl('photo/photo-1.jpg'),
  // getImageUrl('photo/photo-2.jpg'),
  // getImageUrl('photo/photo-3.jpg'),
  // getImageUrl('photo/photo-4.jpg'),
  // getImageUrl('photo/photo-5.jpg'),
  // getImageUrl('photo/photo-6.jpg'),
  // Work
  // getImageUrl('work/carousel/vibia-carousel.jpg'),
  // getImageUrl('work/carousel/pixel-arena-carousel.jpg'),
  // getImageUrl('work/carousel/sergi-comellas-carousel.jpg'),
  // getImageUrl('work/carousel/sonos-roam-carousel.jpg'),
  // getImageUrl('work/carousel/sonos-ace-carousel.jpg'),
  // getImageUrl('work/carousel/archive-carousel.jpg'),
  // Gallery
  // getImageUrl('gallery/01-left.jpg'),
  // getImageUrl('gallery/01-right.jpg'),
  // getImageUrl('gallery/02-left.jpg'),
  // getImageUrl('gallery/02-right.jpg'),
  // Project
  // getImageUrl('work/pixel-arena/pixel-arena-mobile-1.jpg'),
  // getImageUrl('work/pixel-arena/pixel-arena-desktop-1.jpg'),
  // getImageUrl('work/pixel-arena/pixel-arena-mobile-3.jpg'),
  // getImageUrl('work/pixel-arena/pixel-arena-mobile-3.jpg'),

  // getImageUrl('work/sergi-comellas/sergi-comellas-mobile-1.jpg'),
  // getImageUrl('work/sergi-comellas/sergi-comellas-desktop-1.jpg'),
  // getImageUrl('work/sergi-comellas/sergi-comellas-mobile-2.jpg'),
  // getImageUrl('work/sergi-comellas/sergi-comellas-desktop-2.jpg'),

  // getImageUrl('work/sonos-roam/sonos-roam-mobile-1.jpg'),
  // getImageUrl('work/sonos-roam/sonos-roam-desktop-1.jpg'),
  // getImageUrl('work/sonos-roam/sonos-roam-mobile-3.jpg'),
  // getImageUrl('work/sonos-roam/sonos-roam-desktop-3.jpg'),

  // getImageUrl('work/sonos-ace/sonos-ace-mobile-1.jpg'),
  // getImageUrl('work/sonos-ace/sonos-ace-desktop-1.jpg'),
  // getImageUrl('work/sonos-ace/sonos-ace-mobile-3.jpg'),
  // getImageUrl('work/sonos-ace/sonos-ace-desktop-3.jpg'),

  // getImageUrl('work/vibia/vibia-mobile-1.jpg'),
  // getImageUrl('work/vibia/vibia-desktop-1.jpg')
  // getImageUrl('work/vibia/vibia-mobile-3.jpg'),
  // getImageUrl('work/vibia/vibia-desktop-3.jpg'),

  // getImageUrl('work/archive/archive-mobile-1.jpg'),
  // getImageUrl('work/archive/archive-desktop-1.jpg'),
  // getImageUrl('work/archive/archive-mobile-2.jpg'),
  // getImageUrl('work/archive/archive-desktop-2.jpg'),
  // Others
  // getImageUrl('not-found/404-photo.jpg')
]

// View (pure and testable component, receives props from the controller)
export const View = forwardRef<HTMLDivElement, ViewProps>(({ className, handleComplete }, ref) => {
  const counterRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [loadedImages, setLoadedImages] = useState(0)
  const timeline = useRef<gsap.core.Timeline>()
  const progress = preloadImagesArray.length
    ? 100 - ((preloadImagesArray.length - loadedImages) / preloadImagesArray.length) * 100
    : 100

  const [slotMachineProgress, setSlotMachineProgress] = useState(0)

  useEffect(() => {
    const counter = counterRef.current

    const movementDuration = 0.75
    const swipeDuration = 1

    timeline.current = gsap
      .timeline({
        paused: true,
        defaults: {
          ease: 'ease-x-y-percent'
        },
        onComplete: () => {
          gsap.to('.Loader', {
            delay: 0.2,
            duration: swipeDuration,
            ease: 'ease-x-y-percent',
            clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)',
            onComplete: () => {
              handleComplete?.()
            }
          })
        }
      })
      .set('.Loader', {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'
      })
      .set(counter, {
        top: '100%',
        yPercent: -100
      })
      .set(
        wrapperRef.current,
        {
          opacity: 1
        },
        0
      )
      .add(() => {
        setSlotMachineProgress(5)
      }, 0)
      .add(() => {
        setSlotMachineProgress(33)
      }, 0.2)
      .to(
        counter,
        {
          duration: movementDuration,
          top: '66%',
          yPercent: -66
        },
        '>0.2'
      )
      .add(() => {
        setSlotMachineProgress(66)
      }, '>0.2')
      .to(
        counter,
        {
          duration: movementDuration,
          top: '33%',
          yPercent: -33
        },
        '>0.2'
      )
      .add(() => {
        setSlotMachineProgress(100)
      }, '>0.2')
      .to(
        counter,
        {
          duration: movementDuration,
          top: '0%',
          yPercent: 0
        },
        '>0.2'
      )

    return () => {
      timeline?.current?.kill()
    }
  }, [handleComplete])

  useEffect(() => {
    preloadImagesArray?.forEach((image) => {
      const img = new Image()
      img.src = image.src
      img.onload = () => {
        setLoadedImages((prevCount) => prevCount + 1)
      }
    })
  }, [])

  useEffect(() => {
    const totalTimelineDuration = timeline.current?.totalDuration() || 0
    const interPolatedProgress = (progress / 100) * totalTimelineDuration

    timeline.current?.tweenTo(interPolatedProgress)

    if (progress === 100) {
      log('infra', `Loader done - Images loaded: ${preloadImagesArray.length}`)
    }
  }, [progress])

  // Fallback in case something fails
  // Timer of 5 seconds then we force the progress to 100%
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadedImages(preloadImagesArray.length)

      log('infra', 'Skipping loader - slow network')
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className={classNames('Loader', css.root, className)} ref={ref}>
      <div className={css.wrapper} ref={wrapperRef}>
        <SlotMachineCounter className={css.counter} ref={counterRef} toNumber={slotMachineProgress} postfix="" />
      </div>
    </div>
  )
})

View.displayName = 'Loader-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const Loader = forwardRef<HTMLDivElement, LoaderProps>((props, ref) => {
  return <View {...props} ref={ref} />
})

Loader.displayName = 'Loader'

export default memo(Loader)
