export type Route = {
  readonly path: string
  readonly title: string
}

export interface Routes {
  readonly Home: Route
  // readonly About: Route
  readonly Photo: Route
  readonly Gallery: Route
  readonly Work: Route
  readonly Project: Route
  readonly NotFound: Route
}

const routes: Routes = {
  Home: {
    path: '/',
    title: 'Home'
  },
  // About: {
  //   path: '/about',
  //   title: 'About'
  // },
  Photo: {
    path: '/photo',
    title: 'Photo'
  },
  Gallery: {
    path: '/gallery',
    title: 'Gallery'
  },
  Work: {
    path: '/work',
    title: 'Work'
  },
  Project: {
    path: '/work/:slug',
    title: 'Project'
  },
  NotFound: {
    path: '/404',
    title: 'Not Found'
  }
}

export interface ProjectRoutes {
  readonly Vibia: Route
  readonly PixelArena: Route
  readonly SergiComellas: Route
  readonly SonosRoam: Route
  readonly SonosAce: Route
  readonly Archive: Route
}

export const projectRoutes: ProjectRoutes = {
  Vibia: {
    path: '/work/vibia',
    title: 'Vibia'
  },
  PixelArena: {
    path: '/work/pixel-arena',
    title: 'Pixel Arena'
  },
  SergiComellas: {
    path: '/work/sergi-comellas',
    title: 'Sergi Comellas'
  },
  SonosRoam: {
    path: '/work/sonos-roam',
    title: 'Sonos Roam'
  },
  SonosAce: {
    path: '/work/sonos-ace',
    title: 'Sonos Ace'
  },
  Archive: {
    path: '/work/archive',
    title: 'Archive'
  }
}

export default routes
