import { FC, useEffect } from 'react'
import { AppProps } from 'next/app'
import gsap from 'gsap'
import 'normalize.css'

import '@/styles/global.scss'

import { PageProps } from '@/data/types'

import gsapInit from '@/utils/init-gsap'
import log from '@/utils/logger'
import setBodyClasses from '@/utils/set-body-classes'

import Layout from '@/components/Layout/Layout'

require('focus-visible')
gsapInit()

// @TODO: Try in the future to do the layout trick and remove all timeout

// This default export is required in a new `pages/_app.js` file.
const App: FC<AppProps<PageProps>> = (props) => {
  useEffect(() => {
    // @NOTE: Fix scroll restoration issues with animations
    history.scrollRestoration = 'manual'

    log('website', 'Barbara Lopez - Portfolio')

    // Body class names
    setBodyClasses()

    // Fix https://github.com/vercel/next.js/issues/17464
    document.querySelectorAll<HTMLElement>('head > link[rel="stylesheet"]').forEach((node) => {
      delete node.dataset.nG
      delete node.dataset.nP
    })

    // FOUC prevention step 2/2: Make sure the page us un-hidden once application kicks in
    gsap.set(document.documentElement, { autoAlpha: 1 })

    new MutationObserver((mutations) => {
      mutations.forEach(({ target }) => {
        const t = target as Element
        if (t.nodeName === 'STYLE' && t.getAttribute('media') === 'x') t.removeAttribute('media')
      })
    }).observe(document.head, { subtree: true, attributeFilter: ['media'] })
  }, [])

  return <Layout {...props} />
}

export default App
