import { isLocalhost } from '@/utils/runtime-env'

// https://www.materialpalette.com/colors
const colors: { [key: string]: [string, string, string, string] } = {
  scrolling: ['#009688', '#000', '#00796b', '#fff'],
  analytics: ['#80d8ff', '#000', '#0277bd', '#fff'],
  webgl: ['#ff9800', '#000', '#e64a19', '#fff'],
  error: ['#e64a19', '#000', '#ff0000', '#fff'],
  default: ['#ffeb3b', '#000', '#fbc02d', '#000']
}

export const productionLog = (label: string, ...args: string[]) => {
  if (process.env.NODE_ENV !== 'test') {
    const c = colors[label.toLowerCase()] || colors.default
    console.log(
      `%c ${label.toLowerCase()} %c ${args.join(' ')} %c`,
      `background:${c[0]}; padding: 1px; border-radius: 2px; color: ${c[1]}; margin: 0 1px 1px;`,
      `background:${c[2]}; padding: 1px; border-radius: 2px; color: ${c[3]};`,
      `background:transparent`
    )
  }
}

export const log = (label: string, ...args: string[]) => {
  if (isLocalhost() || process.env.NODE_ENV === 'development') productionLog(label, ...args)
}

export default log
