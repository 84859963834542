import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { shallow } from 'zustand/shallow'

import css from './AppAdmin.module.scss'

import localStore, { AppState } from '@/store'

import { device } from '@/utils/detect'
import { productionLog } from '@/utils/logger'
import { getRuntimeEnv, isLocalhost } from '@/utils/runtime-env'

import useWindowSize from '@/hooks/use-window-size'

export interface AppAdminProps {
  className?: string
}

export interface ViewProps extends AppAdminProps {
  env: string
  debugGridVisibility: AppState['app']['debugGridVisibility']
  debugGridTheme: AppState['app']['debugGridTheme']
  setDebugGridVisibility: AppState['app']['setDebugGridVisibility']
  setDebugGridTheme: AppState['app']['setDebugGridTheme']
}

// View (pure and testable component, receives props from the controller)
export const View: FC<ViewProps> = ({
  className,
  env,
  debugGridVisibility,
  setDebugGridVisibility,
  debugGridTheme,
  setDebugGridTheme
}) => {
  const { width, height } = useWindowSize()

  const [open, setOpen] = useState(!!process.env.STORYBOOK || env !== 'local')
  const [render, setRender] = useState(false)
  const [removed, setRemoved] = useState(false)
  const [expanded, setExpanded] = useState(!!process.env.STORYBOOK)
  const [deviceOpen, deviceSetOpen] = useState(true)

  const toggleOpen = useCallback(() => {
    if (open) setExpanded(false)
    setOpen(!open)
  }, [open])

  const toggleExpanded = useCallback(() => setExpanded((e) => !e), [])

  useEffect(() => {
    productionLog(env)
  }, [env])

  useEffect(() => {
    setRender(isLocalhost() && !removed)
  }, [env, removed])

  return render ? (
    <>
      <div className={classNames('AppAdmin', css.root, className)} aria-hidden>
        <div className={classNames(css.basic, { [css.open]: open, [css.closed]: !open })}>
          {open ? (
            <>
              <div>{env}</div>
              &nbsp;
              <button onClick={toggleExpanded}>
                <div>{expanded ? '▼' : '▲'}</div>
              </button>
              <button onClick={toggleOpen}>
                <div>▶</div>
              </button>
            </>
          ) : (
            <button onClick={toggleOpen}>
              <div>◀</div>
            </button>
          )}
        </div>

        {expanded ? (
          <div className={css.details}>
            <div className={css.content}>
              <div className={classNames(css.section, { [css.closed]: deviceOpen })}>
                <h3 className={css.title} onClick={() => deviceSetOpen(!deviceOpen)}>
                  Device info
                </h3>
                {deviceOpen && (
                  <ul>
                    <li>{device.type}</li>
                    <li>
                      {width} x {height}
                    </li>
                    {/* <li>
                      {os.name} {os.version}
                    </li>
                    <li>
                      {browser.name} {browser.version}
                    </li> */}
                  </ul>
                )}
              </div>

              <div className={css.section}>
                <h3 className={css.title} onClick={() => setDebugGridVisibility(!debugGridVisibility)}>
                  {debugGridVisibility ? 'Hide' : 'Show'} Grid
                </h3>

                <h3
                  className={css.title}
                  onClick={() => {
                    if (debugGridTheme === 'light') {
                      setDebugGridTheme('dark')
                    } else {
                      setDebugGridTheme('light')
                    }
                    setDebugGridVisibility(true)
                  }}
                >
                  Toggle Grid Theme [{debugGridTheme}]
                </h3>
              </div>

              <div className={css.section}>
                <h3 className={css.title} onClick={() => setRemoved(true)}>
                  Remove Admin from DOM
                </h3>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  ) : null
}

View.displayName = 'AppAdmin-View'

// Controller (handles global state, router, data fetching, etc. Feeds props to the view component)
const AppAdmin: FC<AppAdminProps> = (props) => {
  const [debugGridVisibility, setDebugGridVisibility, debugGridTheme, setDebugGridTheme] = localStore(
    ({ app }) => [app.debugGridVisibility, app.setDebugGridVisibility, app.debugGridTheme, app.setDebugGridTheme],
    shallow
  )

  const env = useMemo(() => getRuntimeEnv(), [])

  return (
    <View
      {...props}
      debugGridVisibility={debugGridVisibility}
      setDebugGridVisibility={setDebugGridVisibility}
      debugGridTheme={debugGridTheme}
      setDebugGridTheme={setDebugGridTheme}
      env={env}
    />
  )
}

export default memo(AppAdmin)
