export const MOTION = {
  general: {
    delayForLayout: 25, // ms
    carousel: {
      carouselTransitionInMs: 1200, // ms
      carouselTransitionInSec: 1.2, // s
      carouselMobileTransitionInMs: 750, // ms
      carouselMobileTransitionInSec: 0.75 // s
    },
    wipeLayers: {
      duration: 0.8 // s
    }
  }
}
