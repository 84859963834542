import { StateCreator } from 'zustand'

import routes from '@/data/routes'

import { AppState, Mutators } from '.'

export type AppSliceState = {
  app: {
    currentProject: string
    setCurrentProject: (currentProject: string) => void
    currentPath: string
    setCurrentPath: (currentPath: string) => void
    prevPath: string

    debugGridVisibility: boolean
    setDebugGridVisibility: (debugGridVisibility: boolean) => void
    debugGridTheme: 'light' | 'dark'
    setDebugGridTheme: (debugGridTheme: 'light' | 'dark') => void
  }
}

export const AppSlice: StateCreator<AppState, Mutators, [], AppSliceState> = (set) => ({
  app: {
    // current project
    currentProject: '',
    setCurrentProject: (currentProject) => {
      set(
        (state) => {
          state.app.currentProject = currentProject
        },
        false,
        'app.setCurrentProject'
      )
    },
    // previous path
    prevPath: routes.Home.path,
    currentPath: routes.Home.path,
    setCurrentPath: (currentPath) => {
      set(
        (state) => {
          state.app.prevPath = state.app.currentPath
          state.app.currentPath = currentPath
        },
        false,
        'app.setCurrentPath'
      )
    },
    // debug grid theme and visibility
    debugGridVisibility: false,
    setDebugGridVisibility: (debugGridVisibility) => {
      set(
        (state) => {
          state.app.debugGridVisibility = debugGridVisibility
        },
        false,
        'app.setDebugGridVisibility'
      )
    },
    debugGridTheme: 'light',
    setDebugGridTheme: (debugGridTheme) => {
      set(
        (state) => {
          state.app.debugGridTheme = debugGridTheme
        },
        false,
        'app.setDebugGridTheme'
      )
    }
  }
})
